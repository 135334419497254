import { InfoNotification } from '@cb/apricot-react-notification';
import { DrupalFunctionalContent } from '@satui/drupal-profile';
import { HtmlMessage } from '@satui/html-message';

interface IDrupalNotification {
  drupalContent: DrupalFunctionalContent;
}

/**
 * Component to optionally render an apricot notification controlled by drupal profiles functional content.
 * If altTitle field is not present, nothing will render. This allows for PO's to display important notices
 * or notifications without requiring a new build.
 * @param drupalContent
 */
const DrupalNotification: React.FC<IDrupalNotification> = ({ drupalContent }) => {
  if (!drupalContent || !drupalContent.altTitle) return null;

  return (
    <div id="drupal-notification">
      <InfoNotification title={drupalContent.altTitle}>
        <HtmlMessage message={drupalContent.overview || ''} />
      </InfoNotification>
    </div>
  );
};

export default DrupalNotification;
